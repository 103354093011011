export const MeasureUnitsDiameter = [
  {
    label: 'Inches',
    value: 1,
  },
  {
    label: 'Foot',
    value: 12,
  },
  {
    label: 'Yard',
    value: 36,
  },
  {
    label: 'Millimetre',
    value: 0.039,
  },
  {
    label: 'Centimeters',
    value: 0.393,
  },
  {
    label: 'Meters',
    value: 39.37,
  },
];

export const MeasureUnitsVolume = [
  {
    label: 'Fluid ounces',
    value: 0.001044,
  },
  {
    label: 'Quarts',
    value: 0.0334,
  },
  {
    label: 'Pints',
    value: 0.0167,
  },
  {
    label: 'Gallons',
    value: 0.13368,
  },
  {
    label: 'Milimeters',
    value: 0.000035,
  },
  {
    label: 'Liters',
    value: 0.03531,
  },
  {
    label: 'Cubic inches',
    value: 0.00058,
  },
  {
    label: 'Cubic feet',
    value: 1,
  },
  {
    label: 'Cubic centimeters',
    value: 0.0000353,
  },
  {
    label: 'Cubic meters',
    value: 35.31,
  },
];

export const VelocityUnits = [
  {
    label: 'Feet / second',
    value: 1,
  },
  {
    label: 'Meters / second',
    value: 3.28,
  },
];

export const TimeUnits = [
  {
    label: 'Seconds',
    value: 1,
  },
  {
    label: 'Minutes',
    value: 0.01667,
  },
  {
    label: 'Hours',
    value: 0.000277,
  },
  {
    label: 'Days',
    value: 0.00001157,
  },
];
