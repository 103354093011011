import React, { useState } from 'react';
import { Tab } from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { useFormik } from 'formik';
import FormControl from 'components/Core/FormControl';
import Select from 'components/Core/Select';
import TextField from 'components/Core/TextField';
import Button from 'components/Core/Button';
import GatedSubscribeForm from 'components/Tools/GatedSubscribeForm';
import { getFormValid } from 'utils/formUtils';
import FlowRateResult from '../Result';
import { FormulaFlowRate } from '../styles';
import { FormContainer } from '../../styles';
import {
  MeasureUnitsDiameter,
  MeasureUnitsVolume,
  VelocityUnits,
  TimeUnits,
} from '../utils/content';
import HyperLink from '../../../Core/HyperLink';

const FlowRateForm = ({ gatedForm, ...props }) => {
  const [isUpdate, setUpdate] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [gateFormCookieIncrease, setGateFormCookieIncrease] = useState({
    tab1: 0,
    tab2: 0,
  });
  const [isRecalc, setRecalc] = useState({
    tab1: true,
    tab2: true,
  });
  const [doCalc, setDoCalc] = useState({
    tab1: false,
    tab2: false,
  });

  const checkValidate = (value) =>
    value && value !== '' && value.match(/^[0-9.]+$/) && parseFloat(value) > 0;

  const formikTab1 = useFormik({
    initialValues: {
      diameter: '',
      diameterMeas: 1,
      velocity: '',
      velocityMeas: 1,
    },
    validate: (values) => {
      return {
        ...(!checkValidate(values.diameter) && { diameter: 'Diameter is invalid' }),
        ...(!checkValidate(values.velocity) && { velocity: 'Velocity is invalid' }),
      };
    },
    onSubmit: (values) => {
      handleCalcTab1();
    },
  });

  const formikTab2 = useFormik({
    initialValues: {
      volume: '',
      volumeMeas: 0.001044,
      time: '',
      timeMeas: 1,
    },
    validate: (values) => {
      return {
        ...(!checkValidate(values.volume) && { volume: 'Volume is invalid' }),
        ...(!checkValidate(values.time) && { time: 'Time is invalid' }),
      };
    },
    onSubmit: (values) => {
      handleCalcTab2();
    },
  });

  const onHandleChange = (formik, id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);

    if (id === 'diameter' || id === 'diameterMeas' || id === 'velocity' || id === 'velocityMeas') {
      setRecalc({ ...isRecalc, tab1: true });
    }
    if (id === 'volume' || id === 'volumeMeas' || id === 'time' || id === 'timeMeas') {
      setRecalc({ ...isRecalc, tab2: true });
    }
  };

  const handleCalcTab1 = () => {
    setDoCalc({ ...doCalc, tab1: !doCalc.tab1 });
    setRecalc({ ...isRecalc, tab1: false });
    setGateFormCookieIncrease({
      ...gateFormCookieIncrease,
      tab1: gateFormCookieIncrease.tab1 + 1,
    });
  };

  const handleCalcTab2 = () => {
    setDoCalc({ ...doCalc, tab2: !doCalc.tab2 });
    setRecalc({ ...isRecalc, tab2: false });
    setGateFormCookieIncrease({
      ...gateFormCookieIncrease,
      tab2: gateFormCookieIncrease.tab2 + 1,
    });
  };

  const formValidTab1 = getFormValid({ ...formikTab1 });
  const formValidTab2 = getFormValid({ ...formikTab2 });

  return (
    <FormContainer {...props}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container">
          <FormulaFlowRate>
            <TabContext value={activeTab}>
              <TabList onChange={(e, key) => setActiveTab(key)}>
                <Tab label="Diameter & Velocity" value="1" />
                <Tab label="Volume & Time" value="2" />
              </TabList>
              <TabPanel value="1">
                <div className="section-flowrate-form--form">
                  <div className="section-flowrate-form--left-side">
                    <GatedSubscribeForm
                      increaseCookie={gateFormCookieIncrease.tab1}
                      update={isUpdate}
                      gatedForm={gatedForm}
                    >
                      <div className="section-flowrate-form--left-row">
                        <div className="section-flowrate-form--row-title">
                          <h4 className="font-h4">Diameter:</h4>
                        </div>
                        <div className="section-flowrate-form--row-content">
                          <FormControl fullWidth className="section-flowrate-form--input">
                            <TextField
                              id="diameter"
                              type="number"
                              className="value-diameter"
                              values={formikTab1.values}
                              formValid={formValidTab1}
                              handleChange={(id, value) => onHandleChange(formikTab1, id, value)}
                              showOnlyFormError
                              hiddenLabel
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <Select
                              id="diameterMeas"
                              className="section-flowrate-form--select"
                              placeholder="Choose measure unit"
                              options={MeasureUnitsDiameter}
                              values={formikTab1.values}
                              defaultValue={formikTab1.initialValues['diameterMeas']}
                              handleChange={(id, value) => onHandleChange(formikTab1, id, value)}
                              width="100%"
                            />
                          </FormControl>
                        </div>
                      </div>

                      <div className="section-flowrate-form--left-row">
                        <div className="section-flowrate-form--row-title">
                          <h4 className="font-h4">Velocity:</h4>
                        </div>
                        <div className="section-flowrate-form--row-content value-velocity">
                          <FormControl fullWidth className="section-flowrate-form--input">
                            <TextField
                              id="velocity"
                              type="number"
                              values={formikTab1.values}
                              formValid={formValidTab1}
                              handleChange={(id, value) => onHandleChange(formikTab1, id, value)}
                              showOnlyFormError
                              hiddenLabel
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <Select
                              id="velocityMeas"
                              className="section-flowrate-form--select"
                              placeholder="Choose measure unit"
                              options={VelocityUnits}
                              values={formikTab1.values}
                              defaultValue={formikTab1.initialValues['velocityMeas']}
                              handleChange={(id, value) => onHandleChange(formikTab1, id, value)}
                            />
                          </FormControl>
                        </div>
                      </div>

                      <Button
                        shadow={false}
                        onClick={() => formikTab1.submitForm()}
                        width="100%"
                        disabled={!isRecalc.tab1}
                      >
                        Calculate
                      </Button>
                    </GatedSubscribeForm>
                    <p className="section-flowrate-form--label">
                      Learn how we calculated this result{' '}
                      <HyperLink href="#section-tool-page-content--container">below</HyperLink>.
                    </p>
                  </div>

                  <div className="section-flowrate-form--right-side">
                    <FlowRateResult type={1} values={formikTab1.values} doCalc={doCalc.tab1} />
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="section-flowrate-form--form">
                  <div className="section-flowrate-form--left-side">
                    <GatedSubscribeForm
                      gatedForm={gatedForm}
                      increaseCookie={gateFormCookieIncrease.tab2}
                      update={isUpdate}
                    >
                      <div className="section-flowrate-form--left-row">
                        <div className="section-flowrate-form--row-title">
                          <h4 className="font-h4">Volume:</h4>
                        </div>
                        <div className="section-flowrate-form--row-content">
                          <FormControl fullWidth className="section-flowrate-form--input">
                            <TextField
                              id="volume"
                              type="number"
                              values={formikTab2.values}
                              formValid={formValidTab2}
                              handleChange={(id, value) => onHandleChange(formikTab2, id, value)}
                              showOnlyFormError
                              hiddenLabel
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <Select
                              id="volumeMeas"
                              placeholder="Choose measure unit"
                              options={MeasureUnitsVolume}
                              values={formikTab2.values}
                              defaultValue={formikTab2.initialValues['volumeMeas']}
                              handleChange={(id, value) => onHandleChange(formikTab2, id, value)}
                              width="100%"
                            />
                          </FormControl>
                        </div>
                      </div>

                      <div className="section-flowrate-form--left-row">
                        <div className="section-flowrate-form--row-title">
                          <h4 className="font-h4">Time:</h4>
                        </div>
                        <div className="section-flowrate-form--row-content">
                          <FormControl fullWidth className="section-flowrate-form--input">
                            <TextField
                              id="time"
                              type="number"
                              values={formikTab2.values}
                              formValid={formValidTab2}
                              handleChange={(id, value) => onHandleChange(formikTab2, id, value)}
                              showOnlyFormError
                              hiddenLabel
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <Select
                              id="timeMeas"
                              className="section-flowrate-form--select"
                              placeholder="Choose measure unit"
                              options={TimeUnits}
                              values={formikTab2.values}
                              defaultValue={formikTab2.initialValues['timeMeas']}
                              handleChange={(id, value) => onHandleChange(formikTab2, id, value)}
                            />
                          </FormControl>
                        </div>
                      </div>

                      <Button
                        shadow={false}
                        onClick={() => formikTab2.submitForm()}
                        width="100%"
                        disabled={!isRecalc.tab2}
                      >
                        Calculate
                      </Button>
                    </GatedSubscribeForm>
                    <p className="section-flowrate-form--label">
                      Learn how we calculated this result{' '}
                      <HyperLink href="#section-tool-page-content--container">below</HyperLink>.
                    </p>
                  </div>
                  <div className="section-flowrate-form--right-side">
                    <FlowRateResult type={2} values={formikTab2.values} doCalc={doCalc.tab2} />
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </FormulaFlowRate>
        </div>
      </div>
    </FormContainer>
  );
};

export default FlowRateForm;
