import React, { useState, useEffect } from 'react';
import TextField from 'components/Core/TextField';
import { FlowRateResultContainer } from '../styles';

const FlowRateResult = ({ type, values, doCalc, ...otherProps }) => {
  const [result, setResult] = useState({
    FtSec: 0,
    FtMin: 0,
    FtHr: 0,
    MSec: 0,
    MMin: 0,
    MHr: 0,
    GalSec: 0,
    GalMin: 0,
    GalHr: 0,
    LSec: 0,
    LMin: 0,
    LHr: 0,
  });

  useEffect(() => {
    calcFlowRate();
  }, [doCalc]);

  const calcFlowRate = () => {
    let FlowRate = 0;
    if (type === 1) {
      if (values.diameter === '' && values.velocity === '') {
        return;
      }
      const diameterMeas = values.diameter * values.diameterMeas;
      const radius = diameterMeas / 2;
      const velocityMeas = values.velocity * values.velocityMeas;
      const velocityValue = velocityMeas;
      const area = 3.14 * radius * radius;
      const areaValue = area / 144; //  convert the area in square inches to square feet by dividing by 144
      FlowRate = areaValue * velocityValue;
    } else {
      if (values.volume === '' && values.time === '') {
        return;
      }
      const volumeValue = values.volume * values.volumeMeas;
      const timeValue = values.time * values.timeMeas;
      FlowRate = volumeValue / timeValue;
    }

    setResult({
      FtSec: FlowRate.toFixed(4),
      FtMin: (FlowRate * 60).toFixed(4),
      FtHr: (FlowRate * 60 * 60).toFixed(1),
      MSec: (FlowRate * 0.028316).toFixed(4),
      MMin: (FlowRate * 0.028316 * 60).toFixed(3),
      MHr: (FlowRate * 0.028316 * 60 * 60).toFixed(2),
      GalSec: (FlowRate * 7.4805).toFixed(3),
      GalMin: (FlowRate * 7.4805 * 60).toFixed(2),
      GalHr: (FlowRate * 7.4805 * 60 * 60).toFixed(1),
      LSec: (FlowRate * 28.316).toFixed(3),
      LMin: (FlowRate * 28.316 * 60).toFixed(1),
      LHr: (FlowRate * 28.316 * 60 * 60).toFixed(3),
    });
  };

  return (
    <FlowRateResultContainer>
      <div className="section-flowrate-form--right-title">
        <h4>Results</h4>
      </div>

      <div className="section-flowrate-form--right-content">
        <div className="section-flowrate-form--right-row">
          <div className="section-flowrate-form--right-row-content">
            <div className="section-flowrate-form--right-row-field">
              <TextField
                id="result-ft-sec"
                className="section-flowrate-form--input"
                type="text"
                value={result['FtSec']}
                readonly
                showOnlyFormError
              />
              <p className="section-flowrate-form--label">{`ft³/sec`}</p>
            </div>
            <div className="section-flowrate-form--right-row-field">
              <TextField
                id="result-gal-sec"
                className="section-flowrate-form--input"
                type="text"
                value={result['GalSec']}
                readonly
                showOnlyFormError
              />
              <p className="section-flowrate-form--label">{`gal/sec`}</p>
            </div>
          </div>
          <div className="section-flowrate-form--right-row-content">
            <div className="section-flowrate-form--right-row-field">
              <TextField
                id="result-ft-min"
                className="section-flowrate-form--input"
                type="text"
                value={result['FtMin']}
                readonly
                showOnlyFormError
              />
              <p className="section-flowrate-form--label">{`ft³/min`}</p>
            </div>
            <div className="section-flowrate-form--right-row-field">
              <TextField
                id="result-gal-min"
                className="section-flowrate-form--input"
                type="text"
                value={result['GalMin']}
                readonly
                showOnlyFormError
              />
              <p className="section-flowrate-form--label">{`gal/min`}</p>
            </div>
          </div>
          <div className="section-flowrate-form--right-row-content">
            <div className="section-flowrate-form--right-row-field">
              <TextField
                id="result-ft-hr"
                className="section-flowrate-form--input"
                type="text"
                value={result['FtHr']}
                readonly
                showOnlyFormError
              />
              <p className="section-flowrate-form--label">{`ft³/hr`}</p>
            </div>
            <div className="section-flowrate-form--right-row-field">
              <TextField
                id="result-gal-hr"
                className="section-flowrate-form--input"
                type="text"
                value={result['GalHr']}
                readonly
                showOnlyFormError
              />
              <p className="section-flowrate-form--label">{`gal/hr`}</p>
            </div>
          </div>
        </div>

        <div className="section-flowrate-form--right-row">
          <div className="section-flowrate-form--right-row-content">
            <div className="section-flowrate-form--right-row-field">
              <TextField
                id="result-m-sec"
                className="section-flowrate-form--input"
                type="text"
                value={result['MSec']}
                readonly
                showOnlyFormError
              />
              <p className="section-flowrate-form--label">{`m³/sec`}</p>
            </div>
            <div className="section-flowrate-form--right-row-field">
              <TextField
                id="result-l-sec"
                className="section-flowrate-form--input"
                type="text"
                value={result['LSec']}
                readonly
                showOnlyFormError
              />
              <p className="section-flowrate-form--label">{`l/sec`}</p>
            </div>
          </div>
          <div className="section-flowrate-form--right-row-content">
            <div className="section-flowrate-form--right-row-field">
              <TextField
                id="result-m-min"
                className="section-flowrate-form--input"
                type="text"
                value={result['MMin']}
                readonly
                showOnlyFormError
              />
              <p className="section-flowrate-form--label">{`m³/min`}</p>
            </div>
            <div className="section-flowrate-form--right-row-field">
              <TextField
                id="result-l-min"
                className="section-flowrate-form--input"
                type="text"
                value={result['LMin']}
                readonly
                showOnlyFormError
              />
              <p className="section-flowrate-form--label">{`l/min`}</p>
            </div>
          </div>
          <div className="section-flowrate-form--right-row-content">
            <div className="section-flowrate-form--right-row-field">
              <TextField
                id="result-m-hr"
                className="section-flowrate-form--input"
                type="text"
                value={result['MHr']}
                readonly
                showOnlyFormError
              />
              <p className="section-flowrate-form--label">{`m³/hr`}</p>
            </div>
            <div className="section-flowrate-form--right-row-field">
              <TextField
                id="result-l-hr"
                className="section-flowrate-form--input"
                type="text"
                value={result['LHr']}
                readonly
                showOnlyFormError
              />
              <p className="section-flowrate-form--label">{`l/hr`}</p>
            </div>
          </div>
        </div>
      </div>
    </FlowRateResultContainer>
  );
};

export default FlowRateResult;
