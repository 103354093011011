import styled from 'styled-components';

export const FormulaFlowRate = styled.div`
  padding: 20px;
  background: var(--white);
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1);

  .section-flowrate-form--form {
    display: flex;
  }

  .section-flowrate-form--left-side {
    flex: 4;
    padding: 20px;
    position: relative;

    .section-flowrate-form--left-row {
      margin-bottom: 24px;

      .section-flowrate-form--row-content {
        display: flex;
        margin-top: 14px;

        .section-flowrate-form--input {
          width: 100%;
          margin-right: 20px;

          .text-field-input {
            font-size: var(--font-size-18) !important;
            color: var(--grey-6);
            min-width: 160px;
          }
        }

        button {
          margin-top: 6px;
        }
      }
    }

    .section-flowrate-form--label {
      width: 100%;
      text-align: center;
      margin-top: 24px;
      margin-bottom: 0;

      a {
        color: var(--grey-8);
        text-decoration: underline;
      }
    }

    .section-flowrate-form--submit-form {
      .section-flowrate-form--submit-form-title {
        font-weight: var(--font-weight-900);
        font-size: var(--font-size-28);
        line-height: var(--line-height-110);
        display: flex;
        align-items: center;
        letter-spacing: var(--letter-spacing--0-03);
        color: var(--darkest);
      }

      .pipeline-form--subscribe-form {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        button {
          margin: 0;
          width: 100%;
        }
      }
    }
  }

  .section-flowrate-form--right-side {
    background: var(--grey-1);
    border: 1px solid var(--grey-4);
    border-radius: 4px;
    padding: 20px 24px;
    margin-left: 20px;
    flex: 4;
  }

  .MuiTabs-root {
    .MuiTabs-scroller {
      &:before {
        position: absolute;
        border-bottom: 1px solid #f0f0f0;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
      }

      .MuiTab-root {
        background: #fafafa;
        border: 1px solid #f0f0f0;
        margin: 0 2px 0;
        padding: 8px 16px;
        font-family: var(--font-family-body);
        text-transform: none;

        &:hover {
          color: var(--titan-blue-4) !important;
        }

        &.Mui-selected {
          background: #fff;
          color: #1890ff;
          color: var(--titan-blue-3) !important;
          border-bottom: 1px solid #ffffff;
        }
      }
    }

    .MuiTabPanel-root {
      padding: 16px 0 0;
    }

    .MuiTabs-indicator {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    .section-flowrate-form--form {
      flex-direction: column;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 14px 10px;

    .section-flowrate-form--left-side {
      padding: 30px 24px;

      .section-flowrate-form--left-row {
        .section-flowrate-form--row-content {
          .section-flowrate-form--input {
            .text-field-input {
              font-size: var(--font-size-16) !important;
            }
          }

          button {
            padding: 11px 24px;
          }
        }
      }

      .section-flowrate-form--label {
        position: relative;
      }

      .section-flowrate-form--submit-form {
        button {
          padding: 11px 24px;
        }
      }
    }

    .section-flowrate-form--right-side {
      margin: 0 12px 18px;
    }
  }

  @media (max-width: 575px) {
    .section-flowrate-form--left-side {
      padding: 30px 10px;

      .section-flowrate-form--left-row {
        .section-flowrate-form--row-content {
          flex-direction: column;

          .section-flowrate-form--input {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }

      button {
        line-height: 31px;
        padding: 12px 24px;
      }
    }
  }
`;

export const FlowRateResultContainer = styled.div`
  .section-flowrate-form--right-content {
    display: flex;
    flex-direction: column;
    gap: 32px 0;

    .section-flowrate-form--right-row {
      display: flex;
      flex-direction: column;
      gap: 14px 0;

      &:last-child {
        margin-bottom: 0;
      }

      .section-flowrate-form--right-title {
        margin-bottom: 20px;

        h4 {
          font-size: var(--font-size-20);
          font-weight: var(--font-weight-500);
          color: var(--dark);
        }
      }

      .section-flowrate-form--right-row-content {
        display: flex;
        gap: 5px 26px;

        &:last-child {
          margin-bottom: 0;
        }

        .section-flowrate-form--right-row-field {
          display: flex;
          padding: 0;
          flex: 1;

          .section-flowrate-form--input {
            .text-field-input {
              max-width: 130px;
              font-size: var(--font-size-18) !important;
              color: var(--dark);
              padding: 21px 16px;
            }
          }

          .section-flowrate-form--label {
            margin: 0 0 0 10px;
            line-height: 58px;
            min-width: 50px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-flowrate-form--right-content {
      .section-flowrate-form--right-row {
        .section-flowrate-form--right-row-content {
          .section-flowrate-form--right-row-field {
            .section-flowrate-form--label {
              line-height: 48px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .section-flowrate-form--right-content {
      gap: 16px 0;

      .section-flowrate-form--right-row {
        gap: 5px 0;

        .section-flowrate-form--right-row-content {
          flex-direction: column;
          margin-bottom: 0;

          .section-flowrate-form--right-row-field {
            .section-flowrate-form--input {
              width: 100%;

              .text-field-input {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;
