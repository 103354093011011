import React from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import DescriptionBlock from 'components/Tools/DescriptionBlock';
import FlowRateForm from 'components/Tools/FlowRateCalculator/Form';
import { createToolsBreadcrumbs } from './utils/createToolsBreadcrumbs';
import BookmarkContext from 'utils/BookmarkContext';

const FlowRateCalculator = (props) => {
  const title = 'Flow Rate Calculator';
  const data = props.data.contentfulTool;
  const breadcrumbs = createToolsBreadcrumbs(title);

  const bookmarkData = {
    title: data.internalName,
    image: data?.cardImage?.image,
    subTitle: 'Tool',
    url: props.path,
    type: 'tool',
  };

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
        <Hero data={data.hero} />
        <FlowRateForm gatedForm={data.gatedForm} />
        <DescriptionBlock data={data.description} />
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default FlowRateCalculator;

export const FlowRateCalculatorQuery = graphql`
  query FlowRateCalculatorQuery {
    contentfulTool(internalName: { eq: "Flow Rate Calculator" }) {
      ...Tool
    }
  }
`;
